import * as React from 'react';
import { Link } from 'react-router-dom';
import * as classNames from 'classnames';
import { useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import { Button, Drawer, Input, Spinner } from '~/src/components';
import { ORDER_DETAIL_DELETE_MUTATION } from '~src/features/orders/api';
import {
  DISPLAY_INSTANCE_QUERY,
  DISPLAY_REORDER_ORDER_DETAILS_QUERY,
  DISPLAY_REORDER_ORDER_ITEMS_QUERY,
} from '../../api';
import { DisplayReorderDrawerPanel } from '..';
import './DisplayReorderDrawer.scss';

// corresponds with animation duration in DisplayReorderDrawer.scss (line 3)
const EDGE_HIT_TIMEOUT = 800;

export type ReorderItem = {
  product: string;
  position: string;
  group: string;
  data: string;
  quantity: number;
};

type DisplayReorderDrawerProps = {
  isOpen: boolean;
  orderId?: any;
  displayDetailPk?: any;
  onClose: () => void;
  onSave: (data: ReorderItem[], displayDetailPk: number) => void;
};

export const DisplayReorderDrawer = (props: DisplayReorderDrawerProps) => {
  const [reorderItems, setReorderItems] = React.useState<ReorderItem[]>([]);

  const [activePanel, setActivePanel] = React.useState(0);
  const [activeSection, setActiveSection] = React.useState(0);
  const [applyToAllValue, setApplyToAllValue] = React.useState(0);
  const [edgeHit, setEdgeHit] = React.useState(false);

  const [confirmClose, setConfirmClose] = React.useState(false);
  const [loadingExistingItems, setLoadingExistingItems] = React.useState(false);

  // used for showing that there are changes and resetting them
  const [existingReorderItems, setExistingReorderItems] = React.useState<ReorderItem[]>([]);

  const [loadDisplayDetails, { data, loading, error }] = useLazyQuery(DISPLAY_INSTANCE_QUERY);
  const [loadOrderDetails, { data: orderDetailsData }] = useLazyQuery(DISPLAY_REORDER_ORDER_DETAILS_QUERY, {
    fetchPolicy: 'network-only',
  });
  const apolloClient = useApolloClient();
  const [deleteOrderDetail] = useMutation(ORDER_DETAIL_DELETE_MUTATION);

  React.useEffect(() => {
    if (!props.isOpen || !props.displayDetailPk) return;
    loadDisplayDetails({ variables: { pk: props.displayDetailPk } })
      .then((res) => {
        setLoadingExistingItems(true);
        loadOrderDetails({
          variables: { displayDetails: res.data.displayDetail.id, order: props.orderId },
        }).then(async (res) => {
          const orderDetails = res.data.orderDetails.edges.map((edge: any) => edge.node);
          Promise.all(
            orderDetails.map(async (orderDetail: any) => {
              const items = await getAllOrderItems(orderDetail);
              const processed = processOrderItems(items);
              return processed;
            })
          )
            .then((...items) => {
              const orderDetailsToReorderItems = items.flat().flat();
              setExistingReorderItems(orderDetailsToReorderItems);
              const storedReorderItems = JSON.parse(localStorage.getItem(props.displayDetailPk.toString()) || '[]');
              (orderDetailsToReorderItems.length || storedReorderItems.length) &&
                setReorderItems(() => {
                  const union = [...storedReorderItems, ...orderDetailsToReorderItems];
                  const unique = union.filter(
                    (item, index, self) => index === self.findIndex((t) => t.position === item.position)
                  );
                  return unique;
                });
            })
            .finally(() => setLoadingExistingItems(false));
        });
      })
      .catch(console.error);
  }, [props.isOpen, props.displayDetailPk]);

  React.useEffect(() => {
    if (!data) return;
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [data, props.isOpen]);

  React.useEffect(() => {
    if (reorderItems.length && props.displayDetailPk) {
      localStorage.setItem(props.displayDetailPk.toString(), JSON.stringify(reorderItems));
    }
  }, [reorderItems, props.displayDetailPk]);

  React.useEffect(() => {
    if (!edgeHit) return;
    const timeout = setTimeout(() => setEdgeHit(false), EDGE_HIT_TIMEOUT);
    return () => clearTimeout(timeout);
  }, [edgeHit]);

  function onSubmitHandler() {
    Promise.all(
      orderDetailsData?.orderDetails.edges.map((edge: any) => deleteOrderDetail({ variables: { id: edge.node.pk } }))
    );
    localStorage.removeItem(props.displayDetailPk.toString());
    props.onSave(reorderItems, props.displayDetailPk);
    onCloseHandler();
  }

  const hasChanges =
    reorderItems.length !== existingReorderItems.length ||
    reorderItems.some(
      (item) => item.quantity !== existingReorderItems.find((i) => i.position === item.position)?.quantity
    );

  function onCloseHandler() {
    setActivePanel(0);
    setActiveSection(0);
    setApplyToAllValue(0);
    setReorderItems([]);
    setConfirmClose(false);
    props.onClose();
  }

  function handleResetChanges() {
    localStorage.removeItem(props.displayDetailPk.toString());
    setReorderItems(existingReorderItems);
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    if (!data) return;
    const panelCount = data.displayDetail.panels.edges.length;
    const sectionCount = data.displayDetail.panels.edges[0]?.node.sections.edges.length;

    switch (event.key) {
      case 'PageUp':
        setActivePanel((currentPanel) => {
          if (currentPanel === 0) {
            setActiveSection((currentSection) => (currentSection - 1 + sectionCount) % sectionCount);
          }
          return (currentPanel - 1 + panelCount) % panelCount;
        });
        break;
      case 'PageDown':
        setActivePanel((currentPanel) => {
          if (currentPanel === panelCount - 1) {
            setActiveSection((currentSection) => (currentSection + 1) % sectionCount);
          }
          return (currentPanel + 1) % panelCount;
        });
        break;
    }
  };

  function getReorderValueFromOrderItem(orderItem: any) {
    return {
      product: orderItem.product.pk,
      group: orderItem.product.group.prefix,
      data: orderItem.data,
      position: orderItem.position.pk,
      quantity: 1,
    };
  }

  function getReorderValueFromPosition(position: any, quantity: number) {
    const val: ReorderItem = {
      product: position.product.pk,
      position: position.pk,
      group: position.product.group.prefix,
      data: position.variables,
      quantity,
    };
    return val;
  }

  async function getAllOrderItems(orderDetails: any) {
    const pages = Math.ceil(orderDetails.items.totalNodes / 100);
    const items = await Promise.all(
      [...Array(pages)].map((_, idx) =>
        apolloClient.query({
          query: DISPLAY_REORDER_ORDER_ITEMS_QUERY,
          variables: { orderDetails: orderDetails.id, first: 100, offset: idx * 100 },
        })
      )
    ).then((...reqs) => {
      return reqs
        .flat()
        .map((req) => req.data.orderItems.edges.map((edge: any) => edge.node))
        .flat();
    });

    return items;
  }

  function processOrderItems(orderItems: any[]) {
    const reorderValues = orderItems.map((item: any) => getReorderValueFromOrderItem(item));
    return reorderValues.reduce((acc: any[], curr: any) => {
      const existingItem = acc.find((a: any) => a.position === curr.position);
      existingItem ? (existingItem.quantity += 1) : acc.push(curr);
      return acc;
    }, []);
  }

  function handleApplyToAll(val: number) {
    const sections = data.displayDetail.panels.edges
      .map((p: any) => p.node.sections.edges[activeSection])
      .map((e: any) => e.node);
    const sectionModules = sections?.map((s: any) => s.modules.edges.map((e: any) => e.node)).flat();
    const sectionRows = sectionModules?.map((m: any) => m.rows.edges.map((e: any) => e.node)).flat();
    const sectionPositions = sectionRows?.map((r: any) => r.positions.edges.map((e: any) => e.node)).flat();

    setReorderItems((prev) => prev.filter((p) => !sectionPositions.find((sp: any) => sp.pk === p.position)));
    if (val !== 0) {
      setReorderItems((prev) => [
        ...prev,
        ...(sectionPositions?.map((p: any) => getReorderValueFromPosition(p, val)) || []),
      ]);
    }
    setApplyToAllValue(0);
  }

  function getFlatPanelData(panel: any) {
    if (!panel) return {};
    const reducer: (arr: any[], attName: string) => any[] = (arr, attrName) =>
      arr.reduce((acc: any[], node: any) => {
        const nodeAttrArr = node[attrName]?.edges.map((e: any) => e.node) || [];
        return [...acc, ...nodeAttrArr];
      }, []);
    const sections: any[] = panel.sections.edges.map((e: any) => e.node);
    const modules = reducer(sections, 'modules');
    const rows = reducer(modules, 'rows');
    const positions = reducer(rows, 'positions');
    return { sections, modules, rows, positions };
  }

  function getDisplayProductInfo(): { groups: string[]; subtotal: number } {
    return data.displayDetail.panels.edges.reduce(
      (acc: { groups: string[]; subtotal: number }, curr: any) => {
        const panelProductInfo = getFlatPanelData(curr.node).positions?.reduce(
          (acc: { groups: string[]; subtotal: number }, p: any) => {
            const price = p.product?.price;
            const group = p.product?.group.prefix;
            return {
              subtotal: acc.subtotal + price,
              groups: acc.groups.includes(group) ? acc.groups : [...acc.groups, group],
            };
          },
          { groups: [], subtotal: 0 }
        );
        const groupsSet = new Set([...acc.groups, ...panelProductInfo.groups]);
        return { groups: Array.from(groupsSet), subtotal: acc.subtotal + panelProductInfo.subtotal };
      },
      { groups: [], subtotal: 0 }
    );
  }

  function hasProducts() {
    return getFlatPanelData(data.displayDetail.panels.edges[0].node).positions?.some((p: any) => p.product);
  }

  const getQuantity = (groupPrefix?: string) => {
    return reorderItems.reduce((prev: number, curr: any) => {
      if (!groupPrefix) return prev + curr.quantity;
      return curr.group == groupPrefix ? prev + curr.quantity : prev;
    }, 0);
  };

  function renderHeaderTotals() {
    const { groups, subtotal } = getDisplayProductInfo();

    return (
      <span className="DisplayReorderDrawer__header__totals">
        <span className="DisplayReorderDrawer__header__totals__group">
          {groups.map((group: string, idx: number) => (
            <span className="DisplayReorderDrawer__header__totals__fractionTotal" key={idx}>
              <p>{group}</p>
              <p>{getQuantity(group)}</p>
            </span>
          ))}
          <span className="DisplayReorderDrawer__header__totals__fractionTotal">
            <p>Total</p>
            <p>{getQuantity()}</p>
          </span>
        </span>
        <span className="DisplayReorderDrawer__header__totals__fractionTotal">
          <p>Sub-total</p>
          <p>{subtotal.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</p>
        </span>
      </span>
    );
  }

  return !props.displayDetailPk ? null : (
    <Drawer
      backdrop
      isOpen={props.isOpen}
      onClose={onCloseHandler}
      style={{ maxWidth: '90vw', width: '100%' }}
      closeOnBackdropClick={false}
    >
      <div className={classNames('DisplayReorderDrawer', { 'DisplayReorderDrawer--edgeHit': edgeHit })}>
        {loading ? (
          <div className="flex flex-1 justify-center align-center">
            <Spinner message="Loading display details..." />
          </div>
        ) : !data ? (
          <div className="flex flex-1 justify-center align-center">
            <p className="" style={{ fontStyle: 'italic' }}>
              Failed to load display details.
            </p>
          </div>
        ) : (
          <>
            <div className="DisplayReorderDrawer__header">
              <div className="DisplayReorderDrawer__header__title">
                <p>{data.displayDetail.account.name}</p>
                <p>
                  Display ID <span>{data.displayDetail.pk}</span>
                </p>
              </div>
              {data.displayDetail.panels.edges.length && hasProducts() && renderHeaderTotals()}
            </div>
            {data.displayDetail.panels.edges.length && hasProducts() ? (
              <>
                <div className="DisplayReorderDrawer__subheader">
                  <span className="DisplayReorderDrawer__applyToAll">
                    <Input
                      placeholder="0"
                      value={applyToAllValue}
                      onChange={(e) => setApplyToAllValue(Number(e.target.value))}
                    />
                    <Button
                      onClick={() => handleApplyToAll(applyToAllValue)}
                      variant="outlined"
                      disabled={!data.displayDetail.panels.edges.length}
                    >
                      Apply to all
                    </Button>
                  </span>
                  <div className="DisplayReorderDrawer__navigation">
                    {data.displayDetail.panels.edges[0].node.sections?.edges.length ? (
                      <span className="DisplayReorderDrawer__navigation--sections">
                        <p>Sections</p>
                        <ul>
                          {data.displayDetail.panels.edges[0].node.sections.edges.map((section: any, idx: number) => (
                            <li
                              key={idx}
                              className={classNames({
                                DisplayReorderDrawer__activeListItem: activeSection === idx,
                              })}
                              onClick={() => {
                                setActiveSection(idx);
                                setApplyToAllValue(0);
                              }}
                            >
                              <p>{idx + 1}</p>
                            </li>
                          ))}
                        </ul>
                      </span>
                    ) : (
                      <div className="flex align-center">
                        <p>No sections found.</p>
                      </div>
                    )}
                    {data.displayDetail.panels.edges.length ? (
                      <span className="DisplayReorderDrawer__navigation--panels">
                        <p>Panels</p>
                        <ul>
                          {data.displayDetail.panels.edges.map((edge: any, idx: number) => (
                            <li
                              key={idx}
                              className={classNames({
                                DisplayReorderDrawer__activeListItem: activePanel === idx,
                              })}
                              onClick={() => setActivePanel(idx)}
                            >
                              <p>{idx + 1}</p>
                            </li>
                          ))}
                        </ul>
                      </span>
                    ) : (
                      <div className="flex align-center">
                        <p>No panels found.</p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="DisplayReorderDrawer__body">
                  {loadingExistingItems ? (
                    <div className="flex flex-1 justify-center align-center">
                      <Spinner message="Loading existing items..." />
                    </div>
                  ) : (
                    <DisplayReorderDrawerPanel
                      panel={data.displayDetail.panels.edges[activePanel]?.node}
                      activeSection={activeSection}
                      reorderItems={reorderItems}
                      setReorderItems={setReorderItems}
                      onEdgeHit={() => setEdgeHit(true)}
                    />
                  )}
                </div>
              </>
            ) : (
              <div className="flex flex-1 justify-center align-center">
                <p>
                  This display is incomplete. Click{' '}
                  <Link to={`/displays/instances/${props.displayDetailPk}`}>here</Link> to finish building this display.
                </p>
              </div>
            )}
          </>
        )}
        <div className="DisplayReorderDrawer__buttons">
          {loading || !data || error ? (
            <Button color="light" variant="raised" onClick={onCloseHandler} style={{ marginLeft: 'auto' }}>
              Cancel
            </Button>
          ) : (
            <>
              <span>
                <Button variant="outlined" color="dark" key="print">
                  Print
                </Button>
                {hasChanges && (
                  <Button variant="raised" color="dark" onClick={handleResetChanges} key="reset">
                    Reset Changes
                  </Button>
                )}
              </span>
              <span>
                {confirmClose ? (
                  <div className="flex align-center gap-4">
                    <span className="flex flex-column align-end mr-4">
                      <p style={{ fontWeight: 'bold', margin: 0 }}>Are you sure you want to exit?</p>
                      <p> Your progress may not be saved.</p>
                    </span>
                    <Button color="dark" variant="outlined" onClick={() => setConfirmClose(false)}>
                      Cancel
                    </Button>
                    <Button color="warn" variant="raised" onClick={onCloseHandler} key="exit">
                      Exit
                    </Button>
                  </div>
                ) : (
                  <>
                    <Button color="dark" variant="outlined" onClick={() => setConfirmClose(true)}>
                      Exit
                    </Button>
                    <Button
                      key="save"
                      color="primary"
                      variant="raised"
                      onClick={onSubmitHandler}
                      disabled={!reorderItems.length}
                    >
                      {orderDetailsData?.orderDetails.edges.length ? 'Replace' : 'Save'}
                    </Button>
                  </>
                )}
              </span>
            </>
          )}
        </div>
      </div>
    </Drawer>
  );
};
