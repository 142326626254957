import { useMutation, useQuery } from '@apollo/client';
import { MagnifyingGlassIcon, PlusIcon } from '@heroicons/react/24/solid';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import {
  Button,
  Container,
  DataTable,
  DebouncedInput,
  ListPageHeader,
  Nav,
  Pagination,
  Select,
  Spinner,
} from '~/src/components';
import { CREDIT_MEMO_CREATE_MUTATION, CREDIT_MEMO_UPDATE_MUTATION } from '../../api/mutations';
import { CREDIT_MEMOS_SEARCH_QUERY } from '../../api/queries';
import { AddMemoDrawer } from '../../components';
import './CreditMemosList.scss';

const PAGE_SIZE = 50;

type CreditMemosFilters = {
  page: number;
  search: string;
  sort: string;
  status: string | undefined;
};

const defaultFilters: CreditMemosFilters = {
  page: 1,
  search: '',
  sort: 'id',
  status: 'Open',
};

const tableHeaders = [
  { label: 'ID', sort: 'id', size: 'sm' },
  { label: 'Account', sort: 'account__name', size: 'md' },
  // { label: 'Created By', sort: 'created_by__user__first_name', size: 'md' }, // sort does not exist
  { label: 'Created By', size: 'md' },
  { label: 'Approved By', sort: 'approved_by__user__first_name', size: 'md' },
  { label: 'Amount', sort: 'amount', size: 'sm' },
  { label: 'Status', size: 'sm' },
];

export const CreditMemosList = () => {
  const [searchInput, setSearchInput] = React.useState(defaultFilters.search);
  const [filters, setFilters] = React.useState(defaultFilters);
  const [currentMemo, setCurrentMemo] = React.useState<any>({});
  const [addMemoDrawerOpen, setAddMemoDrawerOpen] = React.useState(false);

  const [createCreditMemo] = useMutation(CREDIT_MEMO_CREATE_MUTATION, {
    onCompleted: () => refetch(),
  });
  const [updateCreditMemo] = useMutation(CREDIT_MEMO_UPDATE_MUTATION, {
    onCompleted: () => refetch(),
  });

  const { data, loading, refetch } = useQuery(CREDIT_MEMOS_SEARCH_QUERY, {
    variables: {
      first: PAGE_SIZE,
      offset: (filters.page - 1) * PAGE_SIZE,
      search: filters.search,
      sort: filters.sort,
      status: filters.status,
    },
    fetchPolicy: 'network-only',
  });

  React.useEffect(() => {
    refetch();
  }, [filters]);

  function renderTableItems() {
    return data.creditMemos?.edges.map((memo: any, index: number) => (
      <tr key={index}>
        <td>
          <a onClick={() => (setCurrentMemo(memo.node), setAddMemoDrawerOpen(true))}>{memo.node.pk}</a>
        </td>
        <td>{memo.node.account?.name}</td>
        <td>
          {memo.node.createdBy?.firstName} {memo.node.createdBy?.lastName}
        </td>
        <td>
          {memo.node.approvedBy?.user.firstName} {memo.node.approvedBy?.user.lastName}
        </td>
        <td>
          {memo.node.amount && Number(memo.node.amount).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
        </td>
        <td></td>
      </tr>
    ));
  }

  return (
    <>
      <Helmet>
        <title>BW Portal - Credit Memos</title>
      </Helmet>
      <Nav />
      <Container>
        <ListPageHeader title="Credit Memos" />
        <div className="CreditMemosList__filters">
          <DebouncedInput
            iconTrailing={<MagnifyingGlassIcon />}
            onChange={(e) => {
              setSearchInput(e.target.value);
            }}
            onDebounce={(value) => {
              setFilters({
                ...filters,
                search: value,
                page: 1,
              });
            }}
            placeholder="Search"
            value={searchInput}
          />
          <Select
            // onChange={(e) => setFilters({ ...filters, status: e.target.value, page: 1 })} // doesn't do anything
            options={[
              {
                label: 'Open',
                value: 'open',
              },
              {
                label: 'Completed',
                value: 'completed',
              },
              {
                label: 'Canceled',
                value: 'canceled',
              },
            ]}
          />
          <div className="CreditMemosList__filters__spacer"></div>
          <Button
            color="primary"
            iconLeading={<PlusIcon />}
            onClick={() => setAddMemoDrawerOpen(true)}
            variant="raised"
          >
            New Credit Memo
          </Button>
        </div>
        {loading || !data ? (
          <Container>
            <Spinner message="Loading credit memos..." />
          </Container>
        ) : (
          <>
            <DataTable
              headers={tableHeaders}
              sort={filters.sort}
              onSortChange={(sort) => {
                setFilters({
                  ...filters,
                  sort,
                  page: 1,
                });
              }}
            >
              {renderTableItems()}
            </DataTable>
            <Pagination
              page={filters.page}
              pageSize={PAGE_SIZE}
              onPageChange={(page) => {
                setFilters({
                  ...filters,
                  page: page,
                });
              }}
              hasNextPage={data.creditMemos?.pageInfo.hasNextPage}
              totalNodes={data.creditMemos?.totalNodes || 0}
              totalNodesOnPage={data.creditMemos?.totalNodesOnPage || 0}
            />
          </>
        )}
      </Container>
      <AddMemoDrawer
        memo={currentMemo.hasOwnProperty('id') ? currentMemo : undefined}
        isOpen={addMemoDrawerOpen}
        onClose={() => (setCurrentMemo({}), setAddMemoDrawerOpen(false))}
        onCreate={(memo) => createCreditMemo({ variables: { input: memo } })}
        onUpdate={(pk, input) => updateCreditMemo({ variables: { pk, input } })}
        onDelete={() => {}}
      />
    </>
  );
};
