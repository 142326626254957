import { useLazyQuery } from '@apollo/client';
import { AdjustmentsVerticalIcon, MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  Badge,
  Button,
  Container,
  DataTable,
  DebouncedInput,
  ListPageHeader,
  Nav,
  Pagination,
  Spinner,
} from '~/src/components';
import { PRODUCTS_QUERY } from '../../api';
import { FiltersDrawer, ProductInfoModal } from '../../components';
import './ProductsList.scss';

const PAGE_SIZE = 50;

const tableHeaders = [
  { label: 'product #', sort: 'name' },
  { label: 'product pictures' },
  { label: 'description', size: 'lg', sort: 'description' },
  { label: 'product group', sort: 'group_name' },
  { label: 'group shape', sort: 'shape_name' },
  { label: 'production ready', sort: 'ready_for_production' },
  { label: 'tags', size: 'md' },
  { label: 'last ordered date' },
];

type ProductsFilters = {
  page: number;
  search: string;
  sort: string;
  groups: string[];
  cancelled: boolean;
  active: true | null;
};

const defaultFilters: ProductsFilters = {
  page: 1,
  search: '',
  sort: 'name',
  groups: [],
  cancelled: false,
  active: true,
};

export const ProductsList = () => {
  const [infoModal, setInfoModal] = React.useState<{ open: boolean; product: any }>({
    open: false,
    product: {},
  });
  const [searchInput, setSearchInput] = React.useState('');
  const [filtersDrawerOpen, setFiltersDrawerOpen] = React.useState(false);
  const [filters, setFilters] = React.useState(defaultFilters);

  const [searchProducts, { data, loading }] = useLazyQuery(PRODUCTS_QUERY, {
    variables: {
      search: filters.search,
      group: filters.groups,
      first: PAGE_SIZE,
      offset: (filters.page - 1) * PAGE_SIZE,
      cancelled: filters.cancelled,
      sort: filters.sort,
      active: filters.active,
    },
  });

  React.useEffect(() => {
    searchProducts();
  }, [filters]);

  return (
    <>
      <Helmet>
        <title>BW Portal - Products</title>
      </Helmet>
      <Nav />
      <Container className="ProductsList">
        <ListPageHeader title="View Products" />
        <div className="ProductsList__filters">
          <DebouncedInput
            iconTrailing={<MagnifyingGlassIcon />}
            onChange={(e) => {
              setSearchInput(e.target.value);
            }}
            onDebounce={(value) => {
              setFilters({
                ...filters,
                search: value,
                page: 1,
              });
            }}
            placeholder="Search"
            value={searchInput}
          />
          <Button
            iconLeading={<AdjustmentsVerticalIcon />}
            onClick={() => setFiltersDrawerOpen(true)}
            variant="outlined"
          >
            Filter Products
          </Button>
        </div>
        <div className="ProductsList__table">
          {loading || !data ? (
            <Container>
              <Spinner message="Loading products..." />
            </Container>
          ) : (
            <>
              <DataTable
                className="ProductsList__table"
                headers={tableHeaders}
                sort={filters.sort}
                onSortChange={(sort) =>
                  sort === filters.sort
                    ? setFilters((prev) => ({ ...prev, sort: `-${sort}` }))
                    : setFilters((prev) => ({ ...prev, sort }))
                }
              >
                {data.products?.edges.map((edge: any, index: number) => (
                  <tr key={index}>
                    <td>
                      <Link to={`/products/${edge.node.id}`}>{edge.node.name}</Link>
                    </td>
                    <td className="ProductsList__table__image">
                      <div>
                        {edge.node.artFront && (
                          <img
                            src={`${process.env.OPS_API}/images/${edge.node.pk}/render/?view=product_front`}
                            height="30"
                            style={{ cursor: 'pointer' }}
                            onClick={() => setInfoModal({ open: true, product: edge.node })}
                          />
                        )}
                        {edge.node.artBack && (
                          <img
                            src={`${process.env.OPS_API}/images/${edge.node.pk}/render/?view=product_back`}
                            height="30"
                            style={{ cursor: 'pointer' }}
                            onClick={() => setInfoModal({ open: true, product: edge.node })}
                          />
                        )}
                      </div>
                    </td>
                    <td>{edge.node.description}</td>
                    <td>
                      {edge.node.group.prefix} - {edge.node.group.name}
                    </td>
                    <td>{edge.node.shape?.name}</td>
                    <td>
                      {edge.node.readyForProduction ? (
                        <Badge color="success" label="Yes" />
                      ) : (
                        <Badge color="danger" label="No" />
                      )}
                    </td>
                    <td>{edge.node.tags.edges.map((edge: any) => edge.node.name).join(', ')}</td>
                    <td>{edge.node.lastOrderDate && format(new Date(edge.node.lastOrderDate), 'MM/dd/yyyy')}</td>
                  </tr>
                ))}
              </DataTable>
              <Pagination
                page={filters.page}
                pageSize={PAGE_SIZE}
                onPageChange={(page) => {
                  setFilters((prev) => ({ ...prev, page }));
                }}
                hasNextPage={data.products?.pageInfo.hasNextPage}
                totalNodes={data.products?.totalNodes}
                totalNodesOnPage={data.products?.totalNodesOnPage}
              />
            </>
          )}
        </div>
      </Container>
      <ProductInfoModal
        isOpen={infoModal.open}
        onClose={() => setInfoModal((prev) => ({ ...prev, open: false }))}
        product={infoModal.product}
      />
      <FiltersDrawer
        isOpen={filtersDrawerOpen}
        onClose={() => setFiltersDrawerOpen(false)}
        filters={{ active: filters.active, groups: filters.groups, cancelled: filters.cancelled }}
        onChange={(newFilters) => {
          console.log(newFilters);
          setFilters((prev) => ({
            ...prev,
            groups: newFilters.groups,
            cancelled: newFilters.cancelled,
            active: newFilters.active,
          }));
        }}
      />
    </>
  );
};
