import { useLazyQuery } from '@apollo/client';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  Badge,
  Button,
  Container,
  DataTable,
  DebouncedInput,
  ListPageHeader,
  Nav,
  Pagination,
  Select,
  Spinner,
} from '~/src/components';
import { formatPhoneNumber } from '~/src/utils/formatting';
import avatarImg from '~/src/assets/img/Avatar_Placeholder.png';
import { REPS_QUERY } from '../../api';
import { PhoneListDrawer } from '../../components';
import { formatProfilePicture } from '../RepDetails';
import './RepsList.scss';

const PAGE_SIZE = 50;

type RepsListFilters = {
  active: boolean | null;
  page: number;
  search: string;
  sort: string;
  paymentOn: string | null;
};

const tableHeaders = [
  { label: 'Rep', sort: 'name', size: 'lg' },
  { label: 'Type', sort: 'type', size: 'sm' },
  { label: 'Company', sort: 'company', size: 'md' },
  { label: 'Cell', sort: 'phone', size: 'md' },
  { label: 'City', sort: 'city', size: 'md' },
  { label: 'State', sort: 'state', size: 'sm' },
  { label: 'Status', sort: 'active', size: 'sm' },
  { label: '' },
];

export const RepsList = () => {
  const [filters, setFilters] = React.useState<RepsListFilters>({
    active: null,
    page: 1,
    search: '',
    sort: 'name',
    paymentOn: null,
  });
  const [searchInput, setSearchInput] = React.useState<string>('');
  const [isPhoneListOpen, setIsPhoneListOpen] = React.useState(false);

  const [searchReps, { data, loading }] = useLazyQuery(REPS_QUERY, {
    variables: {
      active: filters.active ? filters.active : filters.active === null ? null : false,
      first: PAGE_SIZE,
      offset: (filters.page - 1) * PAGE_SIZE,
      search: filters.search,
      sort: filters.sort,
      paymentOn: filters.paymentOn,
    },
  });

  React.useEffect(() => {
    searchReps();
  }, [filters]);

  function handleStatusChange(e: React.ChangeEvent<HTMLInputElement>) {
    const activeStatus =
      e.currentTarget.value === 'active' ? true : e.currentTarget.value === 'inactive' ? false : null;
    setFilters((p) => ({
      ...p,
      active: activeStatus,
    }));
  }

  function handlePaymentSelectionChange(e: React.ChangeEvent<HTMLInputElement>) {
    const selectionStatus = e.currentTarget.value ? e.currentTarget.value : null;
    setFilters((p) => ({ ...p, paymentOn: selectionStatus }));
  }

  function handleSearchDebounce(value: string) {
    setFilters((p) => ({ ...p, search: value, page: 1 }));
  }

  function renderActiveStatus(status: boolean) {
    switch (status) {
      case true:
        return <Badge color="success" label="Active" />;
      case false:
        return <Badge color="warning" label="Inactive" />;
      default:
        return <Badge color="light" label="Unsynced" />;
    }
  }

  function renderTableItems() {
    return data.reps.edges?.map((edge: any, index: number) => {
      return (
        <tr key={index}>
          <td className="RepsList__profile">
            <div className="RepsList__profile__image" style={{ width: '40px', height: '40px' }}>
              <img
                className="RepDetails__info__profile__img"
                src={formatProfilePicture(edge.node.user?.profile?.profilePicture) || avatarImg}
              />
            </div>
            <span className="RepsList__profile__info">
              <p className="RepsList__profile__info__name">
                {edge.node.user.firstName} {edge.node.user.lastName}{' '}
              </p>
              <a className="RepsList__profile__info__email" href={`mailto:${edge.node.user.email}`}>
                {edge.node.user.email}
              </a>
            </span>
          </td>
          <td>
            {edge.node.repType === 'REP' && 'Rep'}
            {edge.node.repType === 'REP_GROUP_LEADER' && 'Rep Group Leader'}
            {edge.node.repType === 'COMPANY_SUB_REP' && 'Company Sub Rep'}
          </td>
          <td>{edge.node.company}</td>
          <td>
            {edge.node.user.profile?.cell && (
              <a href={`tel:${formatPhoneNumber(edge.node.user.profile.cell)}`}>
                {formatPhoneNumber(edge.node.user.profile.cell)}
              </a>
            )}
          </td>
          <td>{edge.node.shipCity}</td>
          <td>{edge.node.shipState}</td>
          <td>{renderActiveStatus(edge.node.active)}</td>
          <td>
            <Link to={`/reps/${edge.node.pk}`}>
              <Button color="primary" size="sm">
                View
              </Button>
            </Link>
          </td>
        </tr>
      );
    });
  }

  return (
    <>
      <Helmet>
        <title>BW Portal - Reps</title>
      </Helmet>
      <div className="RepsList">
        <Nav />
        <Container>
          <ListPageHeader title="View Reps" />
          <div className="RepsList__filters">
            <DebouncedInput
              iconTrailing={<MagnifyingGlassIcon />}
              onChange={(e) => {
                setSearchInput(e.currentTarget.value);
              }}
              onDebounce={(value) => handleSearchDebounce(value)}
              placeholder="Search"
              value={searchInput}
            />
            <div>
              <label>Filter by Status</label>
              <Select
                options={[
                  { value: '', label: 'All' },
                  { value: 'active', label: 'Active' },
                  { value: 'inactive', label: 'Inactive' },
                ]}
                onChange={handleStatusChange}
              />
            </div>
            <div>
              <label>Payment on</label>
              <Select
                options={[
                  { value: '', label: 'All' },
                  { value: 'RECEIPT', label: 'Paid on Receipt' },
                  { value: 'SHIPPED', label: 'Shipped' },
                ]}
                onChange={handlePaymentSelectionChange}
              />
            </div>
            <div className="flex-1"></div>
            <Button onClick={() => setIsPhoneListOpen((prev) => !prev)} variant="outlined">
              Phone list
            </Button>
          </div>
          {loading || !data ? (
            <Container>
              <Spinner message="Loading reps..." />
            </Container>
          ) : (
            <>
              <DataTable
                headers={tableHeaders}
                sort={filters.sort}
                onSortChange={(sort) => {
                  setFilters({
                    ...filters,
                    sort,
                    page: 1,
                  });
                }}
              >
                {renderTableItems()}
              </DataTable>
              <Pagination
                page={filters.page}
                pageSize={PAGE_SIZE}
                onPageChange={(page) => {
                  setFilters({
                    ...filters,
                    page,
                  });
                }}
                hasNextPage={data?.reps.pageInfo.hasNextPage}
                totalNodes={data?.reps.totalNodes}
                totalNodesOnPage={data?.reps.totalNodesOnPage}
              />
            </>
          )}
        </Container>
        <PhoneListDrawer
          isOpen={isPhoneListOpen}
          onClose={() => {
            setIsPhoneListOpen((prev) => !prev);
          }}
        />
      </div>
    </>
  );
};
