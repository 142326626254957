import { gql } from '@apollo/client';

export const CREATE_ORDER_ACCOUNTS_QUERY = gql`
  query createOrderAccounts($search: String) {
    accounts(search: $search) {
      edges {
        node {
          id
          pk
          name
          address
          city
          state
          postal
          termsDays
          poNumberReq
          paymentReq {
            id
            pk
            type
            altName
            active
          }
          rep {
            id
            pk
            user {
              firstName
              lastName
            }
          }
          billing {
            id
            pk
            name
            address
            city
            state
            postal
          }
        }
      }
    }
  }
`;

export const CREATE_ORDER_REPS_QUERY = gql`
  query createOrderReps($search: String) {
    reps(active: true, search: $search) {
      edges {
        node {
          id
          pk
          user {
            firstName
            lastName
            email
            profile {
              id
              pk
              phone
              cell
            }
          }
        }
      }
    }
  }
`;

export const CREATE_ORDER_OPEN_ORDERS_QUERY = gql`
  query createOrderOpenOrders($id: ID!) {
    orders(account_Billing: $id, isOpen: true) {
      edges {
        node {
          id
          pk
          shipDate
          termsDays
          daysPastDue
          invoiceTotal
          status
        }
      }
    }
  }
`;

export const ORDERS_QUERY = gql`
  query searchOrders(
    $first: Int
    $offset: Int
    $orderDate_Gte: Date
    $orderDate_Lte: Date
    $search: String
    $sort: String
    $status: String
    $account: ID
    $rep: ID
  ) {
    orders(
      first: $first
      offset: $offset
      orderDate_Gte: $orderDate_Gte
      orderDate_Lte: $orderDate_Lte
      search: $search
      sort: $sort
      status: $status
      account: $account
      rep: $rep
    ) {
      totalNodesOnPage
      totalNodes
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          pk
          account {
            id
            pk
            name
            city
            state
          }
          rep {
            id
            pk
            user {
              firstName
              lastName
            }
          }
          poNumber
          orderDate
          shipDate
          paidDate
          invoiceTotal
          status
        }
      }
    }
  }
`;

export const ORDER_QUERY = gql`
  query order($id: ID!) {
    order(id: $id) {
      id
      pk
      termsDays
      paymentReq {
        id
        pk
        type
      }
      invoiceTotal
      paymentsTotal
      remainingTotal
      status
      invoiceNotes
      paymentReq {
        id
        pk
        type
        altName
        active
      }
      account {
        id
        pk
        notes
        name
        address
        city
        state
        postal
        phone
        email
        poNumberReq
        shipping {
          pk
        }
        chargeShipping
        shipThirdParty
        thirdPartyShipAcct
        termsDays
        paymentReq {
          id
          pk
          type
        }
        contacts {
          edges {
            node {
              name
              phone
              title
              email
            }
          }
        }
        billing {
          id
          pk
          notes
          name
          address
          city
          state
          contacts {
            edges {
              node {
                name
                phone
                title
                email
              }
            }
          }
        }
        rep {
          id
          pk
          user {
            firstName
            lastName
          }
        }
        displaydetailsSet {
          edges {
            node {
              id
              pk
              startDate
              endDate
              displayProduct {
                id
                pk
                name
              }
              product {
                id
                pk
                name
              }
              namelist {
                id
                pk
                name
              }
            }
          }
        }
      }
      rep {
        id
        pk
        notes
        user {
          firstName
          lastName
          email
          profile {
            id
            pk
            phone
            cell
          }
        }
      }
      cancelled
      cancelReason {
        pk
      }
      cancelledDate
      orderDate
      approvedDate
      releaseDate
      completionDate
      shipDate
      paidDate
      poNumber
      holdDate
      sendByDate
      arriveByDate
      priorityType {
        id
        pk
        priorityNumber
        pk
      }
      orderDetails {
        edges {
          node {
            id
            pk
            quantity
            unitPrice
            repComm
            acrylicTabs
            subtotal
            product {
              id
              pk
              name
              description
              minQty
              price
              group {
                name
                prefix
                nonProduct
              }
            }
            displayDetails {
              id
              pk
              displayProduct {
                id
                pk
                name
              }
              product {
                id
                pk
                name
              }
              productSecondary {
                id
                pk
                name
              }
              namelist {
                id
                pk
                name
              }
              productPrice
              startDate
              endDate
            }
          }
        }
      }
      notes
      productionNotes {
        edges {
          node {
            id
            pk
            note
            created
            updated
            author {
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`;

export const ORDER_PAYMENTS_QUERY = gql`
  query orderPaymentsQuery($orderId: ID!) {
    payments(order: $orderId) {
      edges {
        node {
          id
          pk
          paymentType {
            type
            id
            pk
          }
          payAmount
          payDate
          payLastFour
        }
      }
    }
  }
`;

export const SEARCH_PRODUCTS_QUERY = gql`
  query searchProducts($search: String, $includeDisplays: Boolean) {
    products(active: true, search: $search, includeDisplays: $includeDisplays) {
      edges {
        node {
          id
          pk
          name
        }
      }
    }
  }
`;

export const INVOICE_PDF_QUERY = gql`
  query invoicePdf($orderPk: Int!) {
    invoicePdf(orderPk: $orderPk)
  }
`;

export const PACKING_SLIP_QUERY = gql`
  query packingSlip($orderPk: Int!) {
    packingSlip(orderPk: $orderPk)
  }
`;
