import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { ChevronDownIcon, EnvelopeIcon, PrinterIcon } from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import * as React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Button, Container, Nav, Pagination, Popover, Spinner } from '~/src/components';
import { toFixed2 } from '~/src/utils/formatting';
import { useToast } from '~/src/features/toast';
import {
  BILLING_DETAILS_QUERY,
  BILLING_OPEN_ORDERS_QUERY,
  COLLECTION_DETAILS_QUERY,
  COLLECTION_DETAIL_CREATE_MUTATION,
  OPEN_ORDERS_STATEMENT_QUERY,
} from '../../api';
import { BillingCollectionDrawer, InfoSection, OpenOrdersTable } from '../../components';
import { OpenOrdersFilters } from '../../types';
import './BillingDetails.scss';

const defaultOpenOrdersFilters: OpenOrdersFilters = {
  id: null,
  page: 1,
  pageSize: 20,
  sort: 'id',
};

export const BillingDetails = () => {
  const { billingId } = useParams();

  const [toggleBillingCollectionDrawer, setToggleBillingCollectionDrawer] = React.useState<boolean>(false);
  const [openOrdersFilters, setOpenOrdersFilters] = React.useState(defaultOpenOrdersFilters);
  const [contactsDropdown, setContactsDropdown] = React.useState(false);
  const [ordersPopover, setOrdersPopover] = React.useState(false);

  const { data, loading, refetch } = useQuery(BILLING_DETAILS_QUERY, {
    variables: { id: billingId },
    onCompleted: (res) => setOpenOrdersFilters({ ...openOrdersFilters, id: res.billing.id }),
  });
  const [getBillingOpenOrders, { data: billingOrders, loading: billingOrdersLoading }] =
    useLazyQuery(BILLING_OPEN_ORDERS_QUERY);
  const [getCollectionDetails, { data: collectionsData }] = useLazyQuery(COLLECTION_DETAILS_QUERY);
  const [getOpenOrdersStatement] = useLazyQuery(OPEN_ORDERS_STATEMENT_QUERY);
  const [collectionDetailCreate] = useMutation(COLLECTION_DETAIL_CREATE_MUTATION);

  const { success } = useToast();

  React.useEffect(() => {
    if (!data) {
      return;
    }

    getBillingOpenOrders({
      variables: {
        ...openOrdersFilters,
        first: openOrdersFilters.pageSize,
        offset: (openOrdersFilters.page - 1) * openOrdersFilters.pageSize,
      },
    });

    getCollectionDetails({ variables: { billingId: data?.billing.id }, fetchPolicy: 'network-only' });
  }, [openOrdersFilters]);

  function handlePrintOrdersStatement(email = false) {
    getOpenOrdersStatement({
      variables: { billingId: Number(billingId) },
      onCompleted: (res) => {
        email
          ? res.openOrdersStatementEmail === true && success('Email sent!')
          : downloadPdf(res.openOrdersStatementPrint, `OrdersStatement_${billingId}`);
        setOrdersPopover(false);
      },
    });
  }

  function downloadPdf(base64Pdf: string, filename: string) {
    base64Pdf = base64Pdf.replace("b'", 'data:application/pdf;base64,');
    base64Pdf = base64Pdf.replace("'", '');
    const downloadLink = document.createElement('a');
    downloadLink.href = base64Pdf;
    downloadLink.download = `${filename}.pdf`;
    downloadLink.click();
    downloadLink.remove();
  }

  if (loading || !data) {
    return (
      <Container>
        <Spinner message="Loading billing..." />
      </Container>
    );
  }

  return (
    <>
      <Helmet>
        <title>BW Portal - Billing #{billingId}</title>
      </Helmet>
      <div className="BillingDetails">
        <Nav>
          <span className="Nav__title">{data.billing.name}</span>
          <div className="BillingDetails__nav__slash"></div>
          <div className="relative">
            <div className="Nav__item__link" onClick={() => setContactsDropdown((prev) => !prev)}>
              <a>Contacts</a>
              <ChevronDownIcon />
            </div>
            <Popover
              isOpen={contactsDropdown}
              className="BillingDetails__nav__contactsDropdown"
              onClose={() => setContactsDropdown((prev) => !prev)}
            >
              <p>{data.billing.name}-1</p>
              {data.billing.contacts?.edges.length ? (
                <div className="BillingDetails__nav__contactsDropdown__container">
                  <div className="BillingDetails__nav__contactsDropdown__column">
                    {data.billing.contacts?.edges
                      .filter((el: any, index: number) => (index + 1) % 2)
                      .map((edge: any) => (
                        <div className="BillingDetails__nav__contactsDropdown__contact">
                          <p>{edge.node?.name}</p>
                          <p>{edge.node?.title}</p>
                          {edge.node?.phone && <a href={'tel:' + edge.node?.phone}>{edge.node?.phone}</a>}
                          {edge.node?.email && <a href={'mailto:' + edge.node?.email}>{edge.node?.email}</a>}
                        </div>
                      ))}
                  </div>
                  <div className="BillingDetails__nav__contactsDropdown__column">
                    {data.billing.contacts?.edges
                      .filter((el: any, index: number) => (index + 2) % 2)
                      .map((edge: any) => (
                        <div className="BillingDetails__nav__contactsDropdown__contact">
                          <p>{edge.node?.name}</p>
                          <p>{edge.node?.title}</p>
                          {edge.node?.phone && <a href={'tel:' + edge.node?.phone}>{edge.node?.phone}</a>}
                          {edge.node?.email && <a href={'mailto:' + edge.node?.email}>{edge.node?.email}</a>}
                        </div>
                      ))}
                  </div>
                </div>
              ) : (
                <p style={{ fontStyle: 'italic' }}>No contacts found</p>
              )}
            </Popover>
          </div>
          <p className="Nav__item" onClick={() => setToggleBillingCollectionDrawer((prev) => !prev)}>
            <span className="Nav__item__link">Collections</span>
          </p>
          <Link
            to={{
              pathname: '/accounts',
              search: `?search=${encodeURIComponent(data.billing.name)}`,
            }}
            className="Nav__item"
          >
            <span className="Nav__item__link">Accounts</span>
          </Link>
          {/* <Link
          to={{
            pathname: '/orders',
            search: `?search=${encodeURIComponent(data.billing.name)}`,
          }}
          className="Nav__item"
        >
          <span className="Nav__item__link">Orders</span>
        </Link> */}
          <span style={{ position: 'relative' }} className="Nav__item">
            <a onClick={() => setOrdersPopover(true)}>
              <span className="Nav__item__link">Orders</span>
            </a>
            <Popover
              isOpen={ordersPopover}
              onClose={() => setOrdersPopover(false)}
              className="BillingDetails__nav__ordersPopover"
            >
              <p>Open Orders Statement</p>
              <Button
                fluid
                variant="outlined"
                iconLeading={<PrinterIcon />}
                onClick={() => handlePrintOrdersStatement()}
              >
                Print
              </Button>
              <Button
                fluid
                variant="outlined"
                iconLeading={<EnvelopeIcon />}
                onClick={() => handlePrintOrdersStatement(true)}
              >
                Email
              </Button>
            </Popover>
          </span>
        </Nav>
        <Container className="BillingDetails__container">
          <div className="BillingDetails__body">
            <InfoSection data={data.billing} refetch={refetch} />
            <div className="BillingDetails__openOrders">
              <div className="BillingDetails__totals">
                <div>
                  <label className="BillingDetails__totals__label">Total Open:</label>
                  <label className="BillingDetails__totals__label">Total Past Due:</label>
                </div>
                <div>
                  <span className="BillingDetails__totals__value">${toFixed2(data.billing.openSubtotal)}</span>
                  <span className="BillingDetails__totals__value">${toFixed2(data.billing.pastDueSubtotal)}</span>
                </div>
              </div>
              <div className="BillingDetails__openOrders__header">
                <p className="BillingDetails__openOrders__title">Open Invoices</p>
              </div>
              <hr className="divider" />
              {billingOrdersLoading || !billingOrders ? (
                <Container>
                  <Spinner message="Loading open orders..." />
                </Container>
              ) : (
                <>
                  <OpenOrdersTable
                    data={billingOrders.orders}
                    sort={openOrdersFilters.sort}
                    onSortChange={(sort) => {
                      setOpenOrdersFilters({
                        ...openOrdersFilters,
                        sort,
                        page: 1,
                      });
                    }}
                  />
                  {billingOrders.orders.totalNodes >= openOrdersFilters.pageSize && (
                    <Pagination
                      page={openOrdersFilters.page}
                      pageSize={openOrdersFilters.pageSize}
                      onPageChange={(page) => {
                        setOpenOrdersFilters({
                          ...openOrdersFilters,
                          page,
                        });
                      }}
                      hasNextPage={billingOrders.orders.pageInfo.hasNextPage}
                      totalNodes={billingOrders.orders.totalNodes}
                      totalNodesOnPage={billingOrders.orders.totalNodesOnPage}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </Container>
        <BillingCollectionDrawer
          isOpen={toggleBillingCollectionDrawer}
          onClose={() => setToggleBillingCollectionDrawer(false)}
          onAddNote={(detail) => {
            const dateString = `${format(new Date(), 'yyyy-MM-dd')}T${format(new Date(), 'HH:mm:ssXXXX')}`;
            collectionDetailCreate({
              variables: {
                detail: { ...detail, order: Number(detail.order), billing: Number(billingId), deDatetime: dateString },
              },
              onCompleted: () => {
                getCollectionDetails({ variables: { billingId: data?.billing.id }, fetchPolicy: 'network-only' });
              },
            });
          }}
          collectionsData={collectionsData}
          orders={billingOrders?.orders}
        />
      </div>
    </>
  );
};
