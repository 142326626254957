import { useLazyQuery } from '@apollo/client';
import { InformationCircleIcon } from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import * as React from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { DataTable, DatePicker, Pagination, Select, Spinner } from '~/src/components';
import { PRODUCTS_SOLD_BY_DATE_QUERY, PRODUCT_GROUPS_SOLD_BY_DATE_QUERY } from '../../api';
import { ProductsSlugs, ReportsNav } from '../../components';
import './ProductsReports.scss';

const dateTypeOptions = [
  { label: 'Shipped', value: 'Shipped' },
  { label: 'Ordered', value: 'Ordered' },
  { label: 'Approved', value: 'Approved' },
  { label: 'Released', value: 'Released' },
  { label: 'Completed', value: 'Completed' },
];

type ProductsReportsProps = {};

type ProductsFilters = {
  dateType: string;
  dateStart: Date | undefined;
  dateEnd: Date | undefined;
  page: number;
};

const productFiltersDefaults: ProductsFilters = {
  dateType: 'Completed',
  dateStart: undefined,
  dateEnd: undefined,
  page: 1,
};

const PAGE_SIZE = 100;

export const ProductsReports = (props: ProductsReportsProps) => {
  const { type } = useParams();
  const [filters, setFilters] = React.useState<ProductsFilters>(productFiltersDefaults);

  const [searchProducts, { data: productsData, loading: productsLoading }] = useLazyQuery(PRODUCTS_SOLD_BY_DATE_QUERY);
  const [searchGroups, { data: groupsData, loading: groupsLoading }] = useLazyQuery(PRODUCT_GROUPS_SOLD_BY_DATE_QUERY);

  React.useEffect(() => executeLazyQuery(), [filters, type]);

  function executeLazyQuery() {
    if (!filters.dateStart || !filters.dateEnd) return;
    const variables = {
      start: format(new Date(filters.dateStart), 'yyyy-MM-dd'),
      end: format(new Date(filters.dateEnd), 'yyyy-MM-dd'),
      dateType: filters.dateType,
      first: PAGE_SIZE,
      offset: (filters.page - 1) * PAGE_SIZE,
    };

    type === ProductsSlugs.PRODUCTS && searchProducts({ variables });
    type === ProductsSlugs.GROUPS && searchGroups({ variables });
  }

  function renderProductsPage() {
    const headers = [
      { label: 'Product Name', sort: 'name' },
      { label: 'Description', sort: 'description' },
      { label: 'Group', sort: 'group' },
      { label: 'Quantity', sort: 'quantity' },
    ];

    return (
      <div className="ProductsReports__body">
        <div className="ProductsReports__body__header">
          <div className="ProductsReports__body__header__title">
            <h3>Products Sold by Date</h3>
            <p>Track the sales performance of products over a specific time period.</p>
          </div>
          {renderFilters()}
        </div>
        <div className="ProductsReports__body__table">
          {!filters.dateStart || !filters.dateEnd ? (
            <div className="ProductsReports__body__infoBanner">
              <InformationCircleIcon />
              <p>Select a date range to get started.</p>
            </div>
          ) : !productsData || productsLoading ? (
            <Spinner message="Loading products..." />
          ) : (
            <>
              <DataTable headers={headers}>
                {productsData.reportProductsSoldByDate.edges.map((edge: any, index: number) => (
                  <tr key={index}>
                    <td>
                      <Link to={`/`}>{edge.node.product}</Link>
                    </td>
                    <td>{edge.node.description}</td>
                    <td>{edge.node.group}</td>
                    <td>{edge.node.quantity}</td>
                  </tr>
                ))}
              </DataTable>
              <Pagination
                page={filters.page}
                pageSize={PAGE_SIZE}
                hasNextPage={productsData.reportProductsSoldByDate.pageInfo.hasNextPage}
                onPageChange={(page) => setFilters((p) => ({ ...p, page }))}
                totalNodes={productsData.reportProductsSoldByDate.totalNodes}
                totalNodesOnPage={productsData.reportProductsSoldByDate.totalNodesOnPage}
              />
            </>
          )}
        </div>
      </div>
    );
  }

  function renderProductGroupsPage() {
    const headers = [
      { label: 'Product Group', sort: 'name' },
      { label: 'Total Quantity', sort: 'quantity' },
      { label: 'Total Price', sort: 'total_price' },
      { label: 'Average Price', sort: 'average_price' },
    ];

    return (
      <div className="ProductsReports__body">
        <div className="ProductsReports__body__header">
          <div className="ProductsReports__body__header__title">
            <h3>Product Groups Sold by Date</h3>
            <p>Track the sales performance of product groups over a specific time period.</p>
          </div>
          {renderFilters()}
        </div>
        <div className="ProductsReports__body__table">
          {filters.dateStart && filters.dateEnd ? (
            !groupsData || groupsLoading ? (
              <Spinner message="Loading groups..." />
            ) : (
              <>
                <DataTable headers={headers}>
                  {groupsData.reportProductGroupsByDate.edges.map((edge: any, index: number) => (
                    <tr key={index}>
                      <td>{edge.node.group}</td>
                      <td>{edge.node.totalQuantity && Number(edge.node.totalQuantity).toLocaleString('en-US')}</td>
                      <td>
                        {edge.node.totalPrice &&
                          Number(edge.node.totalPrice).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                      </td>
                      <td>
                        {edge.node.averagePrice &&
                          Number(edge.node.averagePrice).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          })}
                      </td>
                    </tr>
                  ))}
                </DataTable>
                <Pagination
                  page={filters.page}
                  pageSize={PAGE_SIZE}
                  hasNextPage={groupsData.reportProductGroupsByDate.pageInfo.hasNextPage}
                  onPageChange={(page) => setFilters((p) => ({ ...p, page }))}
                  totalNodes={groupsData.reportProductGroupsByDate.totalNodes}
                  totalNodesOnPage={groupsData.reportProductGroupsByDate.totalNodesOnPage}
                />
              </>
            )
          ) : (
            <div className="ProductsReports__body__infoBanner">
              <InformationCircleIcon />
              <p>Select a date range to get started.</p>
            </div>
          )}
        </div>
      </div>
    );
  }

  function renderFilters() {
    return (
      <div className="ProductsReports__body__header__filters">
        <div>
          <p>Date type</p>
          <Select
            options={dateTypeOptions}
            onChange={(e) => setFilters((p) => ({ ...p, dateType: e.target.value }))}
            value={filters.dateType}
          />
        </div>
        <div>
          <p>Date Start</p>
          <DatePicker onChange={(d) => setFilters((p) => ({ ...p, dateStart: d }))} value={filters.dateStart} />
        </div>
        <div>
          <p>Date End</p>
          <DatePicker
            onChange={(d) => setFilters((p) => ({ ...p, dateEnd: d }))}
            value={filters.dateEnd}
            style={{ right: '0' }}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>BW Portal - Product Reports</title>
      </Helmet>
      <div className="ProductsReports">
        <ReportsNav active="products" />
        <>
          {type === ProductsSlugs.PRODUCTS && renderProductsPage()}
          {type === ProductsSlugs.GROUPS && renderProductGroupsPage()}
        </>
      </div>
    </>
  );
};
