import { useLazyQuery, useMutation } from '@apollo/client';
import { ExclamationTriangleIcon, MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Badge,
  Button,
  DatePicker,
  Drawer,
  DrawerActions,
  DrawerContent,
  DrawerHeader,
  Input,
  PaymentReqSelect,
  PriorityTypeSelect,
  SearchPopover,
} from '~/src/components';
import { formatDateString, parseDateString, toFixed2 } from '~/src/utils/formatting';
import {
  CREATE_ORDER_ACCOUNTS_QUERY,
  CREATE_ORDER_OPEN_ORDERS_QUERY,
  CREATE_ORDER_REPS_QUERY,
  ORDER_CREATE_MUTATION,
  ORDER_QUERY,
  ORDER_UPDATE_MUTATION,
} from '../../api';
import './CreateOrderDrawer.scss';

type CreateOrderDrawerProps = {
  order?: any;
  isOpen: boolean;
  onClose: () => void;
};

type CreateOrderFormData = {
  account?: number;
  rep?: number;
  paymentReq?: string;
  termsDays?: number;
  orderDate?: Date;
  priorityType?: number;
  poNumber?: string;
};

const defaultFormData: CreateOrderFormData = {
  orderDate: new Date(),
  priorityType: 4, // this value is based off GraphQL pk for "4 - Normal"
  poNumber: 'None',
};

export const CreateOrderDrawer = (props: CreateOrderDrawerProps) => {
  const [searchAccountsOpen, setSearchAccountsOpen] = React.useState(false);
  const [searchRepsOpen, setSearchRepsOpen] = React.useState(false);
  const [formData, setFormData] = React.useState(defaultFormData);
  const [account, setAccount] = React.useState<any>(null);
  const [rep, setRep] = React.useState<any>(null);
  const [openOrders, setOpenOrders] = React.useState<any[]>([]);

  const navigate = useNavigate();
  const [getOpenOrders] = useLazyQuery(CREATE_ORDER_OPEN_ORDERS_QUERY);
  const [createOrder] = useMutation(ORDER_CREATE_MUTATION);
  const [updateOrder] = useMutation(ORDER_UPDATE_MUTATION);

  React.useEffect(() => {
    if (props.order) {
      setAccount(props.order.account);
      setRep(props.order.rep);
      setFormData({
        account: props.order.account.pk,
        rep: props.order.rep?.pk,
        paymentReq: props.order.paymentReq?.pk,
        termsDays: props.order.termsDays,
        orderDate: parseDateString(props.order.orderDate),
        priorityType: props.order.priorityType?.pk,
        poNumber: props.order.poNumber,
      });
      getOpenOrders({
        variables: {
          id: props.order.account.billing?.id,
        },
      }).then((res) => setOpenOrders(res.data?.orders?.edges.map((edge: any) => edge.node)));
    }
  }, [props.order, props.isOpen]);

  function changeAccount(account: any) {
    setAccount(account);
    account.rep && (setRep(account.rep), setFormData((prev) => ({ ...prev, rep: account.rep.pk })));
    setFormData((prev) => ({
      ...prev,
      account: account.pk,
      paymentReq: account.paymentReq?.pk,
      termsDays: account.termsDays,
    }));
    getOpenOrders({ variables: { id: account.billing.id } }).then((res) => {
      if (!res || !res.data || res.data.orders.edges.length < 1) {
        setOpenOrders([]);
        return;
      }
      setOpenOrders(res.data.orders.edges.map((edge: any) => edge.node));
    });
  }

  function setFormDataValue(field: string, value: any) {
    setFormData({ ...formData, [field]: value });
  }

  function selectedRepName() {
    if (!rep) return '';
    return `${rep.user.firstName} ${rep.user.lastName}`;
  }

  function changeRep(rep: any) {
    setRep(rep);
    setFormData({ ...formData, rep: rep.pk });
  }

  function showPOWarning() {
    if (!account) return false;
    return account.poNumberReq && !formData.poNumber;
  }

  function handleClose() {
    setRep(null);
    setAccount(null);
    setFormData(defaultFormData);
    setOpenOrders([]);
    props.onClose();
  }

  function submitDisabled() {
    return !formData.account || !formData.rep || !formData.orderDate || !formData.paymentReq || !formData.priorityType;
  }

  function onSubmit() {
    if (props.order) {
      updateOrder({
        variables: {
          orderPk: props.order.pk,
          input: {
            account: formData.account,
            orderDate: formData.orderDate && format(formData.orderDate, 'yyyy-MM-dd'),
            rep: formData.rep,
            paymentReq: parseInt(formData.paymentReq as string),
            termsDays: formData.termsDays,
            priorityType: formData.priorityType,
            poNumber: formData.poNumber,
          },
        },
        refetchQueries: [{ query: ORDER_QUERY, variables: { id: props.order.id } }],
      }).then(() => handleClose());
      return;
    }
    createOrder({
      variables: {
        input: {
          account: formData.account,
          orderDate: formData.orderDate && format(formData.orderDate, 'yyyy-MM-dd'),
          rep: formData.rep,
          paymentReq: parseInt(formData.paymentReq as string),
          termsDays: formData.termsDays,
          priorityType: formData.priorityType,
          poNumber: formData.poNumber,
        },
      },
    }).then((res) => {
      navigate(`/orders/${res.data.orderCreate.order.id}}`);
      handleClose();
    });
  }

  return (
    <Drawer
      backdrop
      className="CreateOrderDrawer"
      isOpen={props.isOpen}
      onClose={handleClose}
      style={{ width: '672px' }}
      closeOnBackdropClick={false}
    >
      <DrawerHeader onClose={handleClose} title={props.order ? 'Edit order' : 'Create New Order'} />
      <DrawerContent>
        <div className="CreateOrderDrawer__searchAccounts">
          <label>Search accounts</label>
          <div className="relative">
            <Input
              iconLeading={<MagnifyingGlassIcon />}
              readOnly
              onClick={() => setSearchAccountsOpen(true)}
              style={{ width: '224px' }}
              value={account?.name}
            />
            <SearchPopover
              isOpen={searchAccountsOpen}
              onClose={() => setSearchAccountsOpen(false)}
              onChange={(account) => changeAccount(account)}
              popoverStyle={{ maxHeight: '400px', overflowY: 'auto', width: '280px' }}
              query={CREATE_ORDER_ACCOUNTS_QUERY}
              queryArgument="search"
              queryField="accounts"
              renderMatch={(match) => (
                <>
                  {match.pk} - {match.name}
                </>
              )}
              searchLabel="Search accounts"
            />
          </div>
        </div>
        {account && (
          <div className="CreateOrderDrawer__address">
            <div style={{ width: '48%' }}>
              <label>Address</label>
              <p>
                {account.address}
                <br />
                {account.city}, {account.state} {account.postal}
              </p>
            </div>
            <div style={{ width: '48%' }}>
              <label>Billing</label>
              <p>
                {account.billing.name}
                <br />
                {account.billing.address}
                <br />
                {account.billing.city}, {account.billing.state} {account.billing.postal}
              </p>
            </div>
          </div>
        )}
        {account && (
          <div className="CreateOrderDrawer__inputs">
            <div className="flex-1">
              <label>Account</label>
              <hr className="divider" />
              <Input fluid disabled value={`${account.rep.user.firstName} ${account.rep.user.lastName}`} />
              <Input fluid disabled value={`${account.paymentReq?.type}`} />
              <Input disabled value={`${account.termsDays}`} />
            </div>
            <div className="flex-1">
              <label>Order</label>
              <hr className="divider" />
              <div className="relative">
                <Input
                  fluid
                  iconLeading={<MagnifyingGlassIcon />}
                  readOnly
                  onClick={() => setSearchRepsOpen(true)}
                  value={selectedRepName()}
                />
                <SearchPopover
                  isOpen={searchRepsOpen}
                  onClose={() => setSearchRepsOpen(false)}
                  onChange={(rep) => changeRep(rep)}
                  popoverStyle={{ maxHeight: '400px', overflowY: 'auto', width: '280px' }}
                  query={CREATE_ORDER_REPS_QUERY}
                  queryArgument="search"
                  queryField="reps"
                  renderMatch={(match) => (
                    <>
                      {match.pk} - {match.user.firstName} {match.user.lastName}
                    </>
                  )}
                  searchLabel="Search reps"
                />
              </div>
              <PaymentReqSelect
                fluid
                onChange={(e) => setFormDataValue('paymentReq', e.target.value)}
                value={formData.paymentReq}
              />
              <Input
                onChange={(e) => setFormDataValue('termsDays', parseInt(e.target.value))}
                type="number"
                value={formData.termsDays}
              />
            </div>
          </div>
        )}
        {account && openOrders?.length > 0 && (
          <div className="CreateOrderDrawer__openOrders">
            <div className="CreateOrderDrawer__tableLabel">
              <label>Open orders for: {account.billing.name}</label>
            </div>
            <div className="CreateOrderDrawer__tableContent">
              <table className="data-table">
                <thead>
                  <tr>
                    <th>Ship Date</th>
                    <th>Payment Terms</th>
                    <th>Days Past Due</th>
                    <th>Invoice Total</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {openOrders
                    .slice()
                    .sort((order1: any, order2: any) => {
                      // sort edges by most recently added
                      const date1 = new Date(order1.shipDate);
                      const date2 = new Date(order2.shipDate);
                      return date1 > date2 ? -1 : date2 > date1 ? 1 : 0;
                    })
                    .map((order: any, index: number) => {
                      return (
                        <tr key={index}>
                          <td>{formatDateString(order.shipDate)}</td>
                          <td>{order.termsDays}</td>
                          <td>{order.daysPastDue}</td>
                          <td>${toFixed2(order.invoiceTotal)}</td>
                          <td>
                            <Badge label={order.status} />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        )}
        <div className="CreateOrderDrawer__invoice">
          <label>Additional</label>
          <hr className="divider" />
          <div className="orderLabels">
            <p>Order in</p>
            <DatePicker
              onChange={(e) => setFormDataValue('orderDate', e)}
              style={formData.account ? { top: '-300px' } : {}}
              value={formData.orderDate}
            />
          </div>
          <div className="orderLabels">
            <p>Priority Level</p>
            <PriorityTypeSelect
              fluid
              onChange={(e) => setFormDataValue('priorityType', parseInt(e.target.value))}
              value={formData.priorityType}
            />
          </div>
          <div className="orderLabels">
            <p>P.O. #</p>
            <Input fluid onChange={(e) => setFormDataValue('poNumber', e.target.value)} value={formData.poNumber} />
          </div>
        </div>
        {showPOWarning() && (
          <div className="CreateOrderDrawer__callout">
            <div className="CreateOrderDrawer__callout__icon">
              <ExclamationTriangleIcon />
            </div>
            <div>
              <div className="CreateOrderDrawer__callout__title">Warning</div>
              <div className="CreateOrderDrawer__callout__message">
                This account is set to require a P.O. number, are you sure you'd like to continue?
              </div>
            </div>
          </div>
        )}
      </DrawerContent>
      <DrawerActions>
        <Button color="light" onClick={handleClose} variant="raised">
          Cancel
        </Button>
        <Button color="primary" disabled={submitDisabled()} onClick={onSubmit} variant="raised">
          {props.order ? 'Save' : 'Create'}
        </Button>
      </DrawerActions>
    </Drawer>
  );
};
