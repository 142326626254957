import { gql } from '@apollo/client';

export const ORDER_DETAILS_QUERY = gql`
  query orderDetailsQuery($order: ID, $displayDetails: ID!) {
    orderDetails(displayDetails: $displayDetails, order: $order) {
      edges {
        node {
          id
          pk
          quantity
          order {
            id
            pk
            shipDate
          }
        }
      }
    }
  }
`;

export const DISPLAY_REORDER_ORDER_DETAILS_QUERY = gql`
  query displayReorderOrderDetailsQuery($order: ID, $displayDetails: ID!) {
    orderDetails(displayDetails: $displayDetails, order: $order) {
      edges {
        node {
          id
          pk
          items {
            totalNodes
          }
        }
      }
    }
  }
`;

export const DISPLAY_REORDER_ORDER_ITEMS_QUERY = gql`
  query displayReorderOrderItemsQuery($orderDetails: ID, $first: Int, $offset: Int) {
    orderItems(orderDetails: $orderDetails, first: $first, offset: $offset) {
      edges {
        node {
          data
          orderDetails {
            id
            pk
          }
          product {
            id
            pk
            group {
              id
              prefix
            }
          }
          position {
            id
            pk
          }
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;

export const DISPLAY_DETAIL_QUERY = gql`
  query displayDetail($pk: Int!) {
    displayDetail(pk: $pk) {
      id
      pk
      notes
      label
      displayProduct {
        id
        pk
        name
      }
      namelist {
        id
        pk
        name
        notes
      }
      product {
        id
        pk
        name
      }
      productSecondary {
        id
        pk
        name
      }
      startDate
      endDate
      account {
        id
        pk
      }
    }
  }
`;

export const DISPLAY_TEMPLATES_QUERY = gql`
  query displayTemplates($first: Int, $offset: Int, $search: String) {
    displayTemplates(first: $first, offset: $offset, search: $search) {
      edges {
        node {
          pk
          name
          panelData
        }
      }
      totalNodes
      totalNodesOnPage
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const DISPLAY_TEMPLATE_QUERY = gql`
  query displayTemplate($pk: UUID) {
    displayTemplate(pk: $pk) {
      id
      pk
      name
      panelData
      positionData
      panels {
        edges {
          node {
            id
            pk
            order
            sections {
              edges {
                node {
                  id
                  pk
                  order
                  modules {
                    edges {
                      node {
                        id
                        pk
                        order
                        name
                        rows {
                          edges {
                            node {
                              id
                              pk
                              order
                              positions {
                                edges {
                                  node {
                                    id
                                    pk
                                    order
                                    label
                                    positionType
                                    variables
                                    product {
                                      id
                                      pk
                                      description
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const DISPLAY_INSTANCES_QUERY = gql`
  query displayInstances($first: Int, $offset: Int, $search: String) {
    displayDetails(first: $first, offset: $offset, account_Name_Icontains: $search) {
      edges {
        node {
          pk
          account {
            pk
            name
          }
          template {
            pk
            name
          }
          state
          panelData
        }
      }
      totalNodes
      totalNodesOnPage
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const DISPLAY_INSTANCE_QUERY = gql`
  query displayInstance($pk: Int!) {
    displayDetail(pk: $pk) {
      id
      pk
      account {
        id
        pk
        name
      }
      template {
        pk
        name
      }
      panelData
      positionData
      panels {
        edges {
          node {
            id
            pk
            order
            sections {
              edges {
                node {
                  id
                  pk
                  order
                  modules {
                    edges {
                      node {
                        id
                        pk
                        order
                        name
                        rows {
                          edges {
                            node {
                              id
                              pk
                              order
                              positions {
                                edges {
                                  node {
                                    id
                                    pk
                                    order
                                    label
                                    positionType
                                    variables
                                    product {
                                      id
                                      pk
                                      description
                                      price
                                      group {
                                        id
                                        name
                                        prefix
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const DISPLAY_MODULES_QUERY = gql`
  query displayModules($search: String, $section_Isnull: Boolean) {
    displayModules(search: $search, section_Isnull: $section_Isnull) {
      edges {
        node {
          id
          pk
          name
          positionData
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalNodes
      totalNodesOnPage
    }
  }
`;

export const DISPLAY_MODULE_QUERY = gql`
  query displayModule($pk: UUID) {
    displayModule(pk: $pk) {
      id
      pk
      name
      positionData
      rows {
        edges {
          node {
            id
            pk
            order
            positions {
              edges {
                node {
                  order
                  positionType
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const DISPLAY_PRODUCTS_QUERY = gql`
  query displayProductsQuery($search: String, $group: [ID]) {
    products(search: $search, group: $group, first: 20) {
      edges {
        node {
          id
          pk
          name
          description
          artFront {
            layers {
              edges {
                node {
                  name
                  type {
                    name
                  }
                  drawOrder
                  parameters
                  customerFacing
                  variableImagePaths
                }
              }
            }
          }
          artBack {
            layers {
              edges {
                node {
                  name
                  type {
                    name
                  }
                  drawOrder
                  parameters
                  customerFacing
                  variableImagePaths
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const DISPLAY_PRODUCT_QUERY = gql`
  query displayProductQuery($pk: Int!) {
    productPk(pk: $pk) {
      id
      pk
      name
      description
      artFront {
        layers {
          edges {
            node {
              name
              type {
                name
              }
              drawOrder
              parameters
              customerFacing
              variableImagePaths
            }
          }
        }
      }
      artBack {
        layers {
          edges {
            node {
              name
              type {
                name
              }
              drawOrder
              parameters
              customerFacing
              variableImagePaths
            }
          }
        }
      }
    }
  }
`;

export const DISPLAY_PRODUCT_GROUPS_QUERY = gql`
  query displayProductGroups($search: String) {
    groups(search: $search) {
      edges {
        node {
          id
          pk
          name
        }
      }
    }
  }
`;

export const DISPLAY_GROUP_VARIATIONS_QUERY = gql`
  query displayGroupVariations($search: String) {
    groupVariations(search: $search) {
      edges {
        node {
          id
          pk
          shortName
          longName
        }
      }
    }
  }
`;
